import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from './colors';

//icons 
import waiterIcon from '../components/icons/waiter-icon.vue';
import cartIcon from '../components/icons/cart-icon.vue';
import orderIcon from '../components/icons/order-icon.vue';
import restaurantIcon from '../components/icons/restaurant-icon.vue';
import baliLogoHorizontal from '../components/icons/bali-horizontal.vue';
import baliShield from '../components/icons/bali-shield.vue';
import nothingFoundIllustration from '../components/icons/illustrations/nothing-found.vue';
import searchMenuIllustration from '../components/icons/illustrations/searching-menu.vue';


Vue.use(Vuetify);


const balicons = {
  waiterIcon: {
    component: waiterIcon,
  },
  cartIcon: {
    component: cartIcon,
  },
  orderIcon: {
    component: orderIcon,
  },
  restaurantIcon: {
    component: restaurantIcon,
  },
  baliLogoHorizontal: {
    component: baliLogoHorizontal,
  },
  baliShield: {
    component: baliShield,
  },
  nothingFoundIllustration: {
    component: nothingFoundIllustration,
  },
  searchMenuIllustration: {
    component: searchMenuIllustration,
  }
}
export default new Vuetify({
    theme: {
        dark: true,
        options: {
          customProperties: true,
        },
        themes: {
          dark:{
            primary: colors.crimson,
            secondary: colors.eerieblack,
            tertiary: colors.tennesseeOrange,
            accent:  colors.golden,
            light: colors.shades.white,
            dark: colors.shades.black,
            opaque: "ffffff80"
          },
          light:{
            primary: colors.crimson.lighten5,
            secondary: colors.eerieblack.darken4,
            tertiary: colors.tennesseeOrange.darken3,
            accent:  colors.golden.lighten3,
            light: colors.shades.white,
            dark: colors.shades.black,
            opaque: "ffffff80"
          },
        }
    },
    icons: {
      values: balicons,
    }
});
