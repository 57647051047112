import Vue from 'vue'

Vue.mixin({
    data: function() {
        return {
            bottomNavigation: 56,
            menuNavExpanded: 224, 
            menuNavContracted: 104,
        }
    },
    methods: {
        handleResize() { //LAYOUT TOOL: global handler
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        splitDecimals(x){
            let priceSplit = x.toLocaleString().toString().split(".");
            let array = [];

            if( priceSplit[0] !== 'undefined'){                
                array.push(priceSplit[0].toLocaleString('en-US'));
            }

            if( priceSplit[1]){
                if (priceSplit[1].length < 2){// checkup for  JS removal of 0s in cents
                    array.push( "." +priceSplit[1]+ "0");
                }else{
                    array.push( "." +priceSplit[1]);
                }
            }
            else{ // in case it's empty JS will erase 00 
                array.push( ".00");
            }

            return array;

        },
        splitDecimals2(x) {
            let priceSplit = x.toLocaleString('en-US', {minimumFractionDigits: 2}).split('.');
            priceSplit[0] = priceSplit[0].replace(/,/g, ".");
            return priceSplit;
        },
        convertCentsToDollars(cents) {
            return (cents / 100).toFixed(2);
        }
        
    }
})
