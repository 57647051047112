import axios from 'axios';

export default () => {
    return axios.create({
        baseURL: `${process.env.VUE_APP_API_URL}/api`,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Secret": `${process.env.VUE_APP_API_SECRET}`
        }
    });
}